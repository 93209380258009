<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Welcome to Introducer Sign Up</h3>

    <p>More information here</p>


  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";


export default {
  name: 'Introduction',
  components: {},
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      label: 'Welcome' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
