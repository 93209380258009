<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Sign Up Complete</h3>
    <p>You can now login to your account</p>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";


export default {
  name: 'Complete',
  components: {},
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      label: 'Complete', // used for steps progress
      disableStepsNavigation: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
