<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import Introduction from "@/components/common/questionnaires/fieldsets/introducer/Introduction";
import QuestionnaireBase from "@/components/common/questionnaires/questionnaire";
import AccountCreation from "@/components/common/questionnaires/fieldsets/introducer/AccountCreation";
import ContactDetails from "@/components/common/questionnaires/fieldsets/introducer/ContactDetails";
import AdditionalUsers from "@/components/common/questionnaires/fieldsets/introducer/AdditionalUsers";
import PlansBankDetails from "@/components/common/questionnaires/fieldsets/introducer/PlansBankDetails";
import Complete from "@/components/common/questionnaires/fieldsets/introducer/Complete";

export default {
  name: "IntroducerRegister",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    Introduction,
    AccountCreation,
    AdditionalUsers,
    ContactDetails,
    PlansBankDetails,
    Complete
  },
  data() {
    return {
      name: 'Introducer Register',
      title: "Introducer Register",
      url: 'introducer_register',
      order: [ // imported components required here to load
        'Introduction',
        'AccountCreation',
        'ContactDetails',
        'AdditionalUsers',
        'PlansBankDetails',
        'Complete'
      ],
      finishFunction: this.signUpComplete,
      expiryAccessCodeOnFinish: true,
      noInitialBackButton: true
    };
  },
  mounted() {
    // remove exiting logged in user
    if (!this.$route.path.includes('continue')) {
      this.$store.commit('removeTokens');
    } else {
      this.$store.dispatch('setIntroducerAuthorizationHeader')
      this.$store.dispatch('setIntroducerClientAuthorizationHeader')
      this.url = 'introducer_save'
      this.$store.dispatch('userFetch', true)
    }
  },
  methods: {
    async signUpComplete() {
      this.$store.dispatch('logout');
      // this.$router.push({name: 'IntroducerDashboard'})
    }
  }
};
</script>
