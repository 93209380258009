<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Contact Details</h3>

    <IntroducerEmail
        v-model="form.email"
    />

    <IntroducerPhone
        v-model="form.phone"
    />

    <IntroducerAddress v-if="form.id" v-model="form.address" :form="form"/>

    <h3 class="fieldset-heading">Branding</h3>


    <IntroducerLogo
        v-model="form.logo"
        :form="form"
    />


  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import IntroducerEmail from "@/components/common/questionnaires/question/questions/introducer/IntroducerEmail";
import IntroducerPhone from "@/components/common/questionnaires/question/questions/introducer/IntroducerPhone";
import IntroducerAddress from "@/components/common/questionnaires/question/questions/introducer/IntroducerAddress";
import IntroducerLogo from "@/components/common/questionnaires/question/questions/introducer/IntroducerLogo";

export default {
  name: 'ContactDetails',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    IntroducerLogo,
    IntroducerAddress,
    IntroducerPhone,
    IntroducerEmail
  },
  data() {
    return {
      label: 'Company', // used for steps progress
      disableStepsNavigation: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
