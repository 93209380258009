<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors, ...postErrors.email]" :valid="false">
    <InputStandardBasic
        v-model="grant.email"
        :args="args"
        :errors="[...postErrors.email]"
        :loading="loading"
        @buttonClicked="addUser"  />
  </QuestionBase>
</template>

<script>
import { toast } from '@/mixins/toast'
import {http} from "@/services";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";

export default {
  name: 'IntroducerAddAccessUser',
  mixins: [toast],
  components: {
    InputStandardBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: 'Send Access Invitation'
    }
  },
  data () {
    return {
      question: {
        title: this.title
      },
      db: {
        saveLocation: 'introducer', // required db for questionnaire
        saveField: 'introducer_access_requests',
      },
      args: {
        label: 'Enter email address to grant user access, then click add',
        appendButton: true,
        appendText: 'Send Invitation',
        placeholder: 'giant@financial.co.uk'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show,
      grant: {
        email: null
      },
      loading: false,
      postErrors: {
        email: []
      },
    }
  },
  methods: {
    addUser() {
      this.loading = true
      this.clearErrors()
      http.post('introducer_user_access', this.grant).then(
          () => {
            this.toast(`Access email sent to ${this.grant.email}`)
            this.grant.email = null
            this.$emit('refresh')
            this.loading = false
          }
      ).catch(
          error => {
            console.log(error)
            this.handleErrors(error.response.data)
            this.loading = false
          }
      )
    },
    clearErrors() {
      this.postErrors.email = []
    },
    handleErrors(errors) {
      let keys = Object.keys(errors);
      for (let i in keys) {
        this.postErrors[keys[i]] = errors[keys[i]];
      }
    },
  }
}
</script>
