<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Employees</h3>

    <IntroducerNumberOfEmployees
        v-model="form.number_of_employees"
    />

    <h3 class="fieldset-heading">Additional Users</h3>
    <p>If required you can add multiple additional team members to your account, they will have access to the same
      tools as yourself, just enter their email address's below and they will receive an email on how to gain access:
    </p>

    <IntroducerAddAccessUser @refresh="getAccessRequests"/>

    <IntroducerAccessRequests ref="accessRequests"/>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import IntroducerNumberOfEmployees
  from "@/components/common/questionnaires/question/questions/introducer/IntroducerNumberOfEmployees";
import IntroducerAddAccessUser from "@/components/common/questionnaires/question/questions/introducer/IntroducerAddAccessUser";
import IntroducerAccessRequests from "@/components/common/questionnaires/question/questions/introducer/IntroducerAccessRequests";

export default {
  name: 'AdditionalUsers',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    IntroducerAddAccessUser,
    IntroducerNumberOfEmployees,
    IntroducerAccessRequests
  },
  mounted () {
    if (this.$store.getters.user?.id) this.getAccessRequests()
  },
  data() {
    return {
      label: 'Staff', // used for steps progress
      disableStepsNavigation: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    getAccessRequests() {
      this.$refs.accessRequests.getAccessRequests()
    }
  }
};
</script>
