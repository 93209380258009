<template>
  <div>
    <ul v-for="(plan, index) in plans" :key="index">
      <li>
        <ul>
          <li>
            {{ plan.name }} <b-badge v-if="selectedPlan === plan.id">Selected</b-badge>
          </li>
          <li>
            {{ plan.description }}
          </li>
          <li>
            Payment amount is {{ currencyInvoice(plan.payment_amount) }} per month.
          </li>
        </ul>


      </li>
    </ul>
  </div>
</template>

<script>
import {currencyFormat} from "@/mixins/currencyFormat";

export default {
  name: 'PlanInformation',
  mixins: [currencyFormat],
  props: {
    plans: {
      type: Array,
      required: true
    },
    selectedPlan: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      db: {},
      required: false,
      serverErrors: [],
      jsErrors: []
    }
  }
}
</script>
