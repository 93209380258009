<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputRadio
        v-model="data"
        :args="args"
        :db="db"
        :options="options"
        :skipped="skipped"
        class="radio-options-wide"
        @jsErrors="jsErrors=$event"
        @serverErrors="serverErrors=$event"
        @skipped="skipped=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";

export default {
  name: 'IntroducerPlan',
  mixins: [questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: Number,
      required: false
    },
    plans: {
      type: Array,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    options() {
      return this.plans.map(plan => {
        return {
          value: plan.id,
          label: plan.name
        }
      })
    }
  },
  data() {
    return {
      question: {
        title: 'Select a Payment Plan',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: 'introducer_save',
        saveField: 'payment_plan'
      },
      args: {
        placeholder: 'e.g. 01785 901781',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-50'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false
    }
  }
}
</script>
