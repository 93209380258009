<template>
  <div class="fieldset">
    <template>
      <h3 class="fieldset-heading">Plans</h3>

      <IntroducerPlan  v-model="form.payment_plan" :plans="plans" />
      <PlanInformation :plans="plans" :selectedPlan="form.payment_plan" />
    </template>

    <template v-if="form.id">
      <h3 class="fieldset-heading">Bank Details</h3>

      <IntroducerBankAccountName
          v-model="form.bank_details.bank_account_name"/>
      <IntroducerBankAccountSortCode
          v-model="form.bank_details.bank_account_sort_code"/>
      <IntroducerBankAccountNumber
          v-model="form.bank_details.bank_account_number"/>
    </template>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import { http } from "@/services";

import PlanInformation from "@/components/common/questionnaires/question/questions/introducer/PlanInformation";
import IntroducerBankAccountName
  from "@/components/common/questionnaires/question/questions/introducer/IntroducerBankAccountName";
import IntroducerBankAccountSortCode
  from "@/components/common/questionnaires/question/questions/introducer/IntroducerBankAccountSortCode";
import IntroducerBankAccountNumber
  from "@/components/common/questionnaires/question/questions/introducer/IntroducerBankAccountNumber";
import IntroducerPlan from "@/components/common/questionnaires/question/questions/introducer/IntroducerPlan";

export default {
  name: 'PlansBankDetails',
  mixins: [fieldsetHelpers],
  mounted () {
    this.getPlans()
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PlanInformation,
    IntroducerPlan,
    IntroducerBankAccountNumber,
    IntroducerBankAccountSortCode,
    IntroducerBankAccountName,
  },
  data() {
    return {
      label: 'Plans & Bank Details', // used for steps progress
      disableStepsNavigation: true,
      plans: [],
      serverErrors: []
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    getPlans () {
      http.get('introducer_plans').then(
          response => {
            this.plans = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
};
</script>
