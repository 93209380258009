<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputSelect
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :options="options"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />

  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputSelect from "@/components/common/questionnaires/question/inputs/InputSelect";

export default {
  name: 'IntroducerNumberOfEmployees',
  mixins: [questionLoadHelpers],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Number of company employees?',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: 'introducer_save',
        saveField: 'number_of_employees'
      },
      args: {
        placeholder: 'Please select..',
        label: null,
        type: 'string',
        skippable: false,
        required: true,
        inputClass: 'field-33'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      options: [
        {
          label: 'Unknown',
          value: 'Unknown'
        },
        {
          label: '1 - 100',
          value: '1 - 100'
        },
        {
          label: '101 - 1000',
          value: '101 - 1000'
        },
        {
          label: '1001 - 10000',
          value: '1001 - 10000'
        },
        {
          label: '10000+',
          value: '10000+'
        }
      ]

    }
  }
}
</script>
