<template>
  <QuestionBase :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]" :valid="!!data">
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :errors-post="errorsPost"
        :no-save="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'UserName',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What is your name?',
        subTitle:  null,
        tip: null
      },
      db: {
        saveLocation: 'introducer_save',
        saveField: 'email'
      },
      args: {
        placeholder: 'e.g. Simon Jebb',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-50'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
