<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="isValid">
    <b-row class="mb-30">
      <b-col v-if="data" cols="6" md="3">
          <img :src="data" alt="Company Logo"/>
        <b-button v-if="!show.dropZone" @click="removeLogo">Change Logo</b-button>
      </b-col>
      <b-col v-if="show.dropZone">
        <vue-dropzone
            id="dropzone"
            ref="dropzone"
            :awss3="awsS3"
            :options="dropzoneOptions"
            :disabled="false"
            class="d-flex flex-row"
            @vdropzone-success="saveLogoToIntroducer"
            @vdropzone-s3-upload-error="awsError"
            @vdropzone-drag-enter="checkConnection"
            @click="checkConnection"
            @vdropzone-error="awsError"
        ></vue-dropzone>
      </b-col>
    </b-row>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";
import { http } from "@/services";

import QuestionBase from '../../QuestionBase.vue';
import vue2Dropzone from "vue2-dropzone";


export default {
  name: 'IntroducerLogo',
  mixins: [questionLoadHelpers, saveHelpers],
  components: {
    QuestionBase,
    vueDropzone: vue2Dropzone,

  },
  props: {
    value: {
      type: String,
      required: false
    },
    form: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.show.dropZone = !this.data
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(value) {
        this.show.dropZone = !value
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    isValid() {
      return !!this.data
    },
    awsS3() {
      let params = {
        introducer_logo: true
      }

      return {
        signingURL: () => {
          return `${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`
        },
        headers: {
          Authorization: this.$store.getters.accessCode ? `Code ${this.$store.getters.accessCode}` : `JWT ${this.$store.getters.tokens.access}`
        },
        params: params,
        sendFileToServer: false,
        withCredentials: true,
        disablePreviews: true,
        acceptedFiles: true,
        renameFile: this.renameFile

      }
    },
  },
  data() {
    return {
      question: {
        title: 'Your company logo',
        subTitle: 'Please supply your company logo',
        tip: null
      },
      db: {
        saveLocation: 'introducer_save',
        saveField: 'logo',
        // data return from server is inserted into data (required here for image aws file url)
        returnDataInsert: true
      },
      args: {
        placeholder: 'e.g. example@example.com',
        label: null,
        type: 'text',
        skippable: false,
        required: false,
        inputClass: 'field-66'
      },
      show: {
        dropZone: true
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
        maxFilesize: 100,
        headers: {},
        addRemoveLinks: false,
        dictDefaultMessage: 'Drag or Click to upload logo',
        autoProcessQueue: true,
        acceptedFiles: 'image/*',
        resizeHeight: 300
      }
    }
  },
  methods: {
    async saveLogoToIntroducer(file) {
      this.data = file.s3Signature.key
      this.$nextTick(async () => {
        await this.save()
      })

    },
    removeLogo () {
      this.data = null
      this.show.dropZone = true
      this.checkConnection()
    },
    async checkConnection() {
      // checks user login status with server
      return http
          .get('check_connection')
          .then((response) => {
            console.log(response);
            return true;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    awsError(error) {
      console.log('aws error ', error)
    }
  }
}
</script>
