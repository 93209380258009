<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Company Details</h3>

    <Name
        v-model="form.introducer"
        :noSave="!form.id"
        :errors-post="errorsPost.introducer"
    />

    <template v-if="!form.id">

      <h3 class="fieldset-heading">Login details</h3>

      <p>These are you user login details to the introduce tools</p>

      <UserName v-model="form.name"
                :noSave="!form.id"
                :errors-post="errorsPost.name"/>

      <UserEmail
          v-model="form.email"
          :noSave="!form.id"
          :errors-post="errorsPost.email"/>

      <Password
          v-model="form.password"
          :noSave="!form.id"
          :errors-post="errorsPost.password"/>

      <PasswordRepeat
          v-model="form.password_repeat"
          :noSave="!form.id"
          :errors-post="errorsPost.password_repeat"/>

    </template>

  </div>
</template>

<script>
import {toast} from '@/mixins/toast'
import {fieldsetHelpers} from "../fieldsetHelpers";
import {httpQuestionnaire} from "@/services";

import Name from "@/components/common/questionnaires/question/questions/introducer/IntroducerName";
import UserEmail from "@/components/common/questionnaires/question/questions/introducer/UserEmail";
import Password from "@/components/common/questionnaires/question/questions/introducer/Password";
import PasswordRepeat from "@/components/common/questionnaires/question/questions/introducer/PasswordRepeat";
import UserName from "@/components/common/questionnaires/question/questions/introducer/UserName";


export default {
  name: 'AccountCreation',
  mixins: [fieldsetHelpers, toast],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    UserName,
    PasswordRepeat,
    Password,
    UserEmail,
    Name
  },
  data() {
    return {
      label: 'Account', // used for steps progress
      navigateNextFunction: this.validate,
      errorsPost: {}
    }
  },
  methods: {
    async validate() {
      // create introducer

      // guard: do not create if introducer already created (there is an ID)
      if (this.form.id) return true

      this.errorsPost = {}
      return httpQuestionnaire.post('introducer_register', this.form).then(
          response => {
            this.form = response.data.form
            this.$store.dispatch('_setTokens', response.data.tokens)
            this.$store.dispatch('userFetch', true)
            this.toast('Introducer account created', 'Welcome')
            this.$router.push('/introducer_register/continue')
            return true
          }
      ).catch(
          error => {
            this.errorsPost = error.response.data
            return false
          }
      )

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
