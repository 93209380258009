<template>
  <div v-if="accessRequests.length" class="question-box with-arrow mt-32">
    <transition name="fade">
      <QuestionBase
          :question="question"
          :errors="[]"
          :valid="false"
      >

          <div class="accordion sub-items">

            <ObjectCard
                v-for="(access, index) in accessRequests"
                v-model="accessRequests[index]"
                :key="index"
                type="accessRequest"
                :loading="loading"
                :title="`<h4>${access.email}</h4><span class='text-small'>Expiry Date: ${formatDate(access.expiry_date)}</span>`"
                :show-save="false"
                :show-delete="false"
                :disableClick="true"
                @delete="deleteAccessRequestConfirm(access)"
            >
              <template #customButton>
                <div v-b-popover.hover.top="{title: `Revoke`, content: `Revoke access request for ${access.email}`}"
                     @click.stop="deleteAccessRequestConfirm(access)"
                >
                  <i class="i-Remove link text-danger font-weight-bold" />
                </div>
              </template>
            </ObjectCard>

          </div>
      </QuestionBase>
    </transition>
  </div>
</template>

<script>
import {toast} from '@/mixins/toast'
import {http} from "@/services";
import { dateFormat } from "@/mixins/dateFormat";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

export default {
  name: 'IntroducerAccessRequests',
  mixins: [toast, dateFormat],
  components: {
    ObjectCard,
    QuestionBase
  },
  mounted() {
    if (this.$store.getters.user?.id) this.getAccessRequests()
  },
  data() {
    return {
      accessRequests: [],
      question: {title: 'Pending access invitations', subTitle: 'You can revoke access if needed'},
      db: {
        saveLocation: 'introducer', // required db for questionnaire
        saveField: 'introducer_access_requests',
      },
      args: {
        label: 'Enter email address to grant user access, then click add',
        appendButton: true,
        appendText: 'Send Invitation',
        placeholder: 'giant@financial.co.uk'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show,
      postErrors: {
      }
    }
  },
  methods: {
    deleteAccessRequestConfirm(access) {
      this.$bvModal
          .msgBoxConfirm(
              `Are you sure that want to revoke access for ${access.email}`,
              {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                cancelVariant: 'outline-primary',
                okVariant: 'secondary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
          )
          .then((value) => {
            if (value) {
              this.deleteAccessRequest(access)
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    deleteAccessRequest(access) {
      http.delete('introducer_user_access', {params: {id: access.id}}).then(
          () => {
            this.getAccessRequests()
            this.toast('Access revoked')
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
    getAccessRequests() {
      http.get('introducer_user_access').then(
          response => {
            this.accessRequests = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
  }
}
</script>
